import banner from 'assets/images/404.png'
import { NextSeo } from 'next-seo'
import { config } from 'data/config'
import BannerCardSection from 'components/sections/BannerCardSection'
import PublicLayout from 'layouts/PublicLayout'
import { useHasScrolled } from 'data/hooks/useHasScrolled'

const Custom404 = () => {
  const hasScrolled = useHasScrolled(0, true)

  return (
    <PublicLayout
      mobileNavBar={{
        visible: hasScrolled,
        back: true,
        profileTrigger: true,
      }}
    >
      <NextSeo
        title="404 | Page not found"
        description="We can't locate the page your are looking for"
        openGraph={{
          type: 'website',
          url: `${config.appURL}/404`,
          images: [
            {
              url: banner?.src,
              width: 1922,
              height: 760,
            },
          ],
        }}
      />

      <BannerCardSection
        includePadding={false}
        className={{
          container: 'mt-80 sm:mt-100 lg:mt-0 mb-50 lg:mb-0',
        }}
        bannerImage={banner}
        altText="404 Error"
        subtitle="404 Error"
        heading="We can't locate the page you're looking for"
        content={'It looks like the page you are looking for no longer exists. \nNot to worry! You can always access our portfolio, online magazine and contact form directly from our homepage.'}
        buttonText="Back to homepage"
        buttonHref="/"
        smShowContent
      />
    </PublicLayout>
  )
}

export default Custom404
